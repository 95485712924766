.BcBUC, .HGGS1 {
    display: flex;
    flex-direction: column;
}

.BcBUC {
    max-height: 18.4375rem;
}

.HGGS1 {
    align-items: flex-start;
    padding: 1rem 0.5rem;
}

.MWlQt {
    padding: 1rem;
}

.DG9jd {
    display: flex;
    justify-content: space-between;
    padding: 1.375rem var(--p-space-5);
}

.Ot7rf {
    border-left: var(--p-border-width-1) solid var(--p-divider);
    flex-basis: 25%;
    flex-grow: 1;
    padding-left: var(--p-space-4);
}

.Ot7rf:first-of-type {
    border-left: 0;
    padding-left: 0;
    padding-right: var(--p-space-4);
}

.cF9ji {
    margin-bottom: var(--p-space-8);
}
